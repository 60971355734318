import {Stack, useTheme} from '@mui/material';
import {FC} from 'react';
import {
    Location
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/partials/parameters/partials/location/Location';
import {
    Title
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/partials/parameters/partials/title/Title';
import {
    Price
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/partials/parameters/partials/price/Price';
import {
    AdvertisementsInfo
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/partials/parameters/partials/advertisementsInfo/AdvertisementsInfo';
import {
    ECategoriesLabel
} from 'modules/theme/components/listing/cards/promoDevProjectCard/enums/category/ECategoriesLabel';

interface IParameters {
    price: string;
    location: string;
    unitPrice?: string;
    projectName: string;
    categories: ECategoriesLabel[];
    totalAdvertisementsCount: number;
}

export const Parameters: FC<IParameters> = ({
    price,
    location,
    categories,
    unitPrice,
    projectName,
    totalAdvertisementsCount,
}) => {
    const {palette} = useTheme();

    return <Stack direction="column" gap={.5} color={palette.labelPrimaryInverse.main}>
        <Title projectName={projectName}/>
        <Location location={location}/>
        <AdvertisementsInfo categories={categories} totalAdvertisementsCount={totalAdvertisementsCount}/>
        <Price price={price} unitPrice={unitPrice}/>
    </Stack>;
};
