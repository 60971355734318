import { FC } from 'react';
import { Box } from '@mui/material';
import { Header } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/modal/partials/header/Header';
import { Content } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/modal/partials/content/Content';
import { IModalItem } from 'components/page/homePage/interfaces/whatAreYouLookingFor/IModalItem';

interface IModal {
    title: string;
    onClose: () => void;
    items: IModalItem[];
    onTitleClick?: () => void;
}

export const Modal: FC<IModal> = ({ title, onClose, items, onTitleClick }) => {
    return (
        <>
            <Box mb={1}>
                <Header title={title} onClick={onClose} onTitleClick={onTitleClick} />
            </Box>
            <Box>
                <Content items={items} />
            </Box>
        </>
    );
};
