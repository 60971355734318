import { useLocalStorageItem } from 'modules/localStorage/hooks/useLocalStorageitem';

interface IReturn {
    handleAfterClose: () => void;
}

export const useOnLoginModalClose = (): IReturn => {
    const {
        remove: removeStorageAdvId,
    } = useLocalStorageItem('add-to-favorites');

    const {
        remove: removeSearchAgentShowLSItem,
    } = useLocalStorageItem('isSearchAgentClicked');

    const handleAfterClose = (): void => {
        removeStorageAdvId();
        removeSearchAgentShowLSItem();
    };

    return {
        handleAfterClose,
    };
};