import {FC} from 'react';
import {Stack} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import bulletIcon from 'modules/theme/components/icons/basic/Bullet.svg';
import {Parameter} from 'modules/theme/components/listing/cards/common/components/parameters/common/Parameter';
import {
    ECategoriesLabel
} from 'modules/theme/components/listing/cards/promoDevProjectCard/enums/category/ECategoriesLabel';
import {
    Categories
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/partials/parameters/partials/advertisementsInfo/partials/Categories';


interface IAdvertisementsInfo {
    categories: ECategoriesLabel[];
    totalAdvertisementsCount: number;
}

export const AdvertisementsInfo: FC<IAdvertisementsInfo> = ({categories, totalAdvertisementsCount}) => {
    const totalCountValue = `Počet inzerátov ${totalAdvertisementsCount}`;

    return <Stack direction="row" gap={.3} alignItems="center">
        <Categories categories={categories}/>
        <Svg icon component={bulletIcon} width={8}/>
        <Parameter variant="label2" value={totalCountValue} isLast noWrap/>
    </Stack>;
};
