import { IPopularRegion } from 'components/page/homePage/components/popularRegions/common/interfaces/IPopularRegion';

export const popularRegionItems: IPopularRegion[] = [
    {
        sef: 'banska-bystrica',
        linkText: 'Reality Banská Bystica',
        abbreviation: 'BB',
    },
    {
        sef: 'bratislava',
        linkText: 'Reality Bratislava',
        abbreviation: 'BA',
    },
    {
        sef: 'kosice',
        linkText: 'Reality Košice',
        abbreviation: 'KE',
    },
    {
        sef: 'martin',
        linkText: 'Reality Martin',
        abbreviation: 'MT',
    },
    {
        sef: 'nitra',
        linkText: 'Reality Nitra',
        abbreviation: 'NR',
    },
    {
        sef: 'poprad',
        linkText: 'Reality Poprad',
        abbreviation: 'PP',
    },
    {
        sef: 'presov',
        linkText: 'Reality Prešov',
        abbreviation: 'PO',
    },
    {
        sef: 'prievidza',
        linkText: 'Reality Prievidza',
        abbreviation: 'PD',
    },
    {
        sef: 'ruzomberok',
        linkText: 'Reality Ružomberok',
        abbreviation: 'RK',
    },
    {
        sef: 'trencin',
        linkText: 'Reality Trenčín',
        abbreviation: 'TN',
    },
    {
        sef: 'trnava',
        linkText: 'Reality Trnava',
        abbreviation: 'TT',
    },
    {
        sef: 'zilina',
        linkText: 'Reality Žilina',
        abbreviation: 'ZA',
    }
];