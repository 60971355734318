import { FC } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import Link from 'components/link/Link';
import { IModalItem } from 'components/page/homePage/interfaces/whatAreYouLookingFor/IModalItem';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { useRecoilState } from 'recoil';
import { appUserIsLoggedIn } from 'modules/state/app/state';
import { getClickOnCategoryEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';

interface IItem {
    item: IModalItem;
    divider: boolean;
}

export const Item: FC<IItem> = ({ item, divider }) => {
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);

    return (
        <Link
            noUnderline
            href={item.link}
            onClick={() =>
                gtmSendEvent(
                    getClickOnCategoryEvent(isAdvertiserLoggedIn, item.parentSef, item.sef)
                )
            }
        >
            <Box
                borderBottom={(theme: Theme) =>
                    divider && `1px solid ${theme.palette.dividerTransparent}`
                }
                paddingTop={'12px'}
                paddingBottom={'12px'}
            >
                <Stack direction="row" gap={1} alignItems="center">
                    <Svg icon component={item.icon} width={24} />
                    <Text variant="body2">{item.label}</Text>
                </Stack>
            </Box>
        </Link>
    );
};
