import {FC} from 'react';
import { Box } from '@mui/material';
import Image from 'components/image/Image';
import iconUrl from 'modules/theme/components/assets/badges/svg/DevProject.svg?url';

export const Label: FC = ({}) => {
    return (
        <Box height={48} width={48}>
            <Image
                alt=""
                width={48}
                height={48}
                src={iconUrl}
            />
        </Box>
    );
};
