import { FC, useEffect } from 'react';
import { PromoDevProjectCard } from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/PromoDevProjectCard';
import { Box } from '@mui/material';
import { DevProjectsList } from 'components/page/homePage/components/devProjects/list/DevProjectsList';
import { useDevProjects } from 'components/page/homePage/components/devProjects/common/useDevProjects';
import { ECategoryLabelRecord } from 'components/page/homePage/components/devProjects/common/helpers/ECategoryLabelRecord';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {
    getPromoDevProjectsEvent,
    getSelectItemEvent,
    getSelectPromotionEvent,
    getViewDevProjectsEvent
} from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { IHomePageDevProject } from 'modules/api/clients/homePage/interfaces/devProjects/IHomePageDevProject';

interface IDevProjects {
    devProjectsInitial: IHomePageDevProject[];
}

export const DevProjects: FC<IDevProjects> = ({
    devProjectsInitial,
}) => {
    const { devProjects, isFetching, fetchDevProjects } = useDevProjects(devProjectsInitial);
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();

    const items = devProjects?.map((devProject, index) => {
        return (
            <PromoDevProjectCard
                key={devProject.id}
                devProject={{
                    price: {
                        price: devProject.priceInfo.price,
                        unitPrice: devProject.priceInfo.unitPrice
                    },
                    projectId: devProject.id,
                    imageUrl: devProject.photoUrl,
                    location: devProject.location?.name,
                    packages: {
                        isPremium: devProject.flags.isPremium,
                        isTop: devProject.flags.isTop
                    },
                    projectName: devProject.title,
                    projectSlugName: devProject.sefName,
                    totalAdvertisementsCount: devProject.advertisementInfo.totalCount,
                    advertisementsCategories: [ECategoryLabelRecord[devProject.advertisementInfo.category]]
                }}
                devProjectDetailUrl={devProject.detailUrl}
                onProjectClick={() => {
                    gtmSendEvent(
                        getResetEcommerceEvent(),
                        getSelectPromotionEvent(devProject, index),
                        getSelectItemEvent(devProject, index)
                    );
                }}
            />
        );
    });

    useEffect(() => {
        if (devProjects?.length === 0) return;
        if (isFetching) return;

        gtmSendEvent(
            getResetEcommerceEvent(),
            getPromoDevProjectsEvent(devProjects),
            getViewDevProjectsEvent(devProjects)
        );
    }, [isFetching, devProjects, gtmSendEvent]);

    return (
        <Box>
            <DevProjectsList items={items} fetchDevProjects={fetchDevProjects}/>
        </Box>
    );
};
