import { keyframes } from '@emotion/react';

export const spinCounterClockwiseKeyframes = keyframes`
0% {
    transform: rotate(0deg);
}
80% {
    transform: rotate(-125deg);
}
100% {
    transform: rotate(-120deg);
}
`;

export const spinClockwiseKeyframes = keyframes`
0% {
    transform: rotate(0deg);
}
80% {
    transform: rotate(125deg);
}
100% {
    transform: rotate(120deg);
}
`;

export const sunriseLeftDesktopKeyframes = keyframes`
0% {
    margin-left: 43px;
    margin-top: 40px;
}
100% {
    margin-left: 8px;
    margin-top: 0px;
}
`;

export const sunriseLeftMobileKeyframes = keyframes`
0% {
    margin-left: 25px;
    margin-top: 25px;
}
100% {
    margin-left: 0px;
    margin-top: 0px;
}
`;

export const sunriseRightDesktopKeyframes = keyframes`
0% {
    margin-left: 43px;
    margin-top: 40px;
}
100% {
    margin-left: 72px;
    margin-top: 0px;
}
`;

export const sunriseRightMobileKeyframes = keyframes`
0% {
    margin-left: 25px;
    margin-top: 25px;
}
100% {
    margin-left: 45px;
    margin-top: 0px;
}
`;