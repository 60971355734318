import { FC } from 'react';
import { Stack } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
interface IPrice {
    price: string;
    unitPrice?: string;
}

export const Price: FC<IPrice> = ({ price, unitPrice }) => {
    return (
        <Stack direction="row" gap={1} alignItems="baseline">
            <Text variant="body2" semibold inheritColor noWrap>
                {price}
            </Text>
            {unitPrice && (
                <Text variant="footnote" inheritColor noWrap>
                    / {unitPrice}
                </Text>
            )}
        </Stack>
    );
};
