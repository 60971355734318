import { FC } from 'react';
import { IModalItem } from 'components/page/homePage/interfaces/whatAreYouLookingFor/IModalItem';
import { Item } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/modal/partials/content/partials/items/Items';

interface IContent {
    items: IModalItem[];
}

export const Content: FC<IContent> = ({ items }) => {
    return (
        <>
            {items.map((item, index) => {
                const isNotLastItem = index !== items.length - 1;
                return (
                    <Item
                        item={item}
                        key={index}
                        divider={isNotLastItem}
                    />
                );

            }
            )}
        </>
    );
};
