export enum EBadgeType {
    BASIC = 'BASIC',
    TOP = 'TOP',
    PREMIUM = 'PREMIUM',
    EXSLUSIVE = 'Exkluzívne',
    AGENT = 'MAKLÉR',
    AGENT_PRO = 'MAKLÉR ',
    RESERVED = 'REZERVOVANÉ',
    PRIVATE_PERSON = 'SÚKROMNÁ OSOBA',
}
