import { HomePageDevProjectsDevProjectDto } from 'modules/api/generated';
import { IHomePageDevProject } from 'modules/api/clients/homePage/interfaces/devProjects/IHomePageDevProject';
import { mapHomePageDevProjectsAdvertisementInfoDtoToAdvertisementInfoInterfaceMapper } from 'modules/api/clients/homePage/helpers/mappers/devProjects/advertisementInfo/homePageDevProjectsAdvertisementInfoDtoToAdvertisementInfoInterfaceMapper';
import { mapHomePageDevProjectsFlagsDtoToFlagsInterfaceMapper } from 'modules/api/clients/homePage/helpers/mappers/devProjects/flags/homePageDevProjectsFlagsDtoToFlagsInterfaceMapper';
import { mapPriceInfoDtoToIPriceInfo } from 'modules/api/clients/advertisement/detail/helpers/mappers/devExclusiveProjects/partials/projects/price/mapPriceInfoDtoToIPriceInfo';
import { mapHomePageDevProjectsLocationDtoToLocationInterfaceMapper } from 'modules/api/clients/homePage/helpers/mappers/devProjects/location/homePageDevProjectsLocationDtoToLocationInterfaceMapper';
import { mapHomePageDevProjectsAdvertiserDtoToIAdvertiser } from 'modules/api/clients/homePage/helpers/mappers/devProjects/advertiser/homePageDevProjectsAdvertiserDtoToIAdvertiser';

export const mapHomePageDevProjectsDtoToDevProjectsInterface = (dto: HomePageDevProjectsDevProjectDto[]): IHomePageDevProject[] => {
    return dto.map((devProject => ({
        id: devProject.id,
        title: devProject.title,
        sefName: devProject.sefName,
        location: mapHomePageDevProjectsLocationDtoToLocationInterfaceMapper(devProject.location),
        photoUrl: devProject.photoUrl,
        detailUrl: devProject.detailUrl,
        transaction: devProject.transaction,
        realEstateState: devProject.realEstateState,
        priceInfo: mapPriceInfoDtoToIPriceInfo(devProject.priceInfo),
        advertiser: mapHomePageDevProjectsAdvertiserDtoToIAdvertiser(devProject.advertiser),
        advertisementInfo: mapHomePageDevProjectsAdvertisementInfoDtoToAdvertisementInfoInterfaceMapper(devProject.advertisementsInfo[0]),
        flags: mapHomePageDevProjectsFlagsDtoToFlagsInterfaceMapper(devProject.flags),
    })));
};