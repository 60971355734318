import {FC} from 'react';
import {Stack} from '@mui/material';
import {Badge} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/badge/partials/Badge';
import {EBadgeType} from 'modules/theme/components/listing/cards/advertisementCard/enums/badge/EBadgeType';

interface IBadges {
    types: EBadgeType[]
}

export const Badges: FC<IBadges> = ({types}) => {
    return <Stack
        gap={1.5}
        direction="row"
        flexWrap="wrap"
    >
        {types.map((type, index) =>
            <Badge type={type} key={`${index} ${type}`}/>
        )}
    </Stack>;
};
