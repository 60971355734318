import { FC } from 'react';
import { Box, Container, Skeleton } from '@mui/material';

export const CarouselLoader: FC = () => {

    return (
        <Container maxWidth={'lg'} disableGutters={false}>
            <Box position={'relative'}>
                <Box mb={{ xs: 2, md: 3 }}>
                    <Skeleton
                        height={36}
                        sx={{
                            maxWidth: 350,
                            width: '100%',
                            marginBottom: 1,
                            '&.MuiSkeleton-text': {
                                transform: 'none'
                            }
                        }}
                    />
                    <Skeleton
                        height={26}
                        sx={{
                            maxWidth: 450,
                            width: '100%',
                            '&.MuiSkeleton-text': {
                                transform: 'none'
                            }
                        }}
                    />
                </Box>
                <Box>
                    <Skeleton
                        height={290}
                        sx={{
                            width: '100%',
                            '&.MuiSkeleton-text': {
                                transform: 'none'
                            }
                        }}
                    />
                </Box>
            </Box>
        </Container>
    );
};