import { IPriceInfo } from 'modules/api/clients/advertisement/common/interfaces/price/IPriceInfo';
import { AdvertisementDetailAdvertisementPriceInfoDto, HomePageDevProjectsDevProjectPriceInfoDto } from 'modules/api/generated';
import { priceCurrencyEnumsRecord } from 'modules/api/clients/advertisement/common/constants/records/parameters/price/priceCurrencyEnumsRecord';
import { priceUnitsEnumsRecord } from 'modules/api/clients/advertisement/common/constants/records/parameters/price/priceUnitsEnumsRecord';
import { priceOptionEnumsRecord } from 'modules/api/clients/advertisement/common/constants/records/parameters/price/priceOptionEnumsRecord';
import { formatPrice } from 'modules/api/clients/advertisement/common/helpers/formatPrice/formatPrice';

export const mapPriceInfoDtoToIPriceInfo = (
    dto: AdvertisementDetailAdvertisementPriceInfoDto | HomePageDevProjectsDevProjectPriceInfoDto
): IPriceInfo => {
    const { pricePrimary, priceSecondary, priceOption } = dto;

    let price = undefined;
    let unitPrice = undefined;
    let hasPriceOption = false;

    if (pricePrimary) {
        const currency = priceCurrencyEnumsRecord[pricePrimary.currency];
        const unit = priceUnitsEnumsRecord[pricePrimary.unit];

        price = `od ${formatPrice(pricePrimary.value, currency, unit)}`;
    }

    if (priceOption) {
        hasPriceOption = true;
        price = priceOptionEnumsRecord[priceOption];
    }

    if (priceSecondary) {
        const currency = priceCurrencyEnumsRecord[priceSecondary.currency];
        const unit = priceUnitsEnumsRecord[priceSecondary.unit];

        unitPrice = `od ${formatPrice(priceSecondary.value, currency, unit)}`;
    }

    return {
        hasPriceOption,
        ...(!!price && { price }),
        ...(!!unitPrice && { unitPrice }),
    };
};
