import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { Box, Link, Theme, useTheme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { appUserIsLoggedIn } from 'modules/state/app/state';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { IAdditionalService } from 'components/additionalServicesList/interfaces/IAdditionalService';
import { getAdvertisementServicesClickEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';

export const AdditionalServiceCard: FC<IAdditionalService> = ({
    icon,
    name,
    url,
    gtmLabel,
}) => {
    const { palette } = useTheme();

    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);

    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    return (
        <Link
            href={url}
            underline="none"
            onClick={() =>
                gtmLabel &&
                gtmSendEvent(getAdvertisementServicesClickEvent(
                    gtmLabel,
                    isAdvertiserLoggedIn,
                ))
            }
        >
            <Box
                height={136}
                paddingY={3}
                paddingX={1.5}
                display="flex"
                borderRadius={2}
                textAlign="center"
                alignItems="center"
                boxSizing="border-box"
                flexDirection="column"
                justifyContent="center"
                whiteSpace="break-spaces"
                width={{ xs: 152, md: 181 }}
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
                bgcolor={palette.backgroundPrimary.main}
                border={(theme) =>
                    '1px solid ' + theme.palette.dividerTransparent
                }
                sx={(theme: Theme) => ({
                    '&:hover': {
                        border: '2px solid ' + theme.palette.labelPrimary.main,
                    },
                    '&:active': {
                        bgcolor: palette.backgroundSecondary.main,
                        border: '2px solid ' + theme.palette.labelPrimary.main,
                    },
                })}
            >
                <Box>{icon}</Box>
                <Text variant={'body2'} semibold>
                    {name}
                </Text>
            </Box>
        </Link>
    );
};
