import React, { FC } from 'react';
import { Box } from '@mui/material';
import { AdditionalServiceCard } from 'components/additionalServicesList/partials/AdditionalServiceCard';
import { IAdditionalService } from 'components/additionalServicesList/interfaces/IAdditionalService';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

interface IAdditionalServicesStatic {
    services: IAdditionalService[]
}

export const AdditionalServicesStatic: FC<IAdditionalServicesStatic> = ({services}) => {

    return (
        <Box
            display='flex'
            overflow='hidden'
            alignItems="center"
            justifyContent="center"
            pb={1}
            mb={4}
            sx={{
                overflowX: 'auto',
                overflow: 'hidden'
            }}
        >
            {services.map((info, index) => (
                <Box key={`${info.url}-${index}`} mr={1}>
                    <AdditionalServiceCard
                        icon={info.icon}
                        name={info.name}
                        gtmLabel={info.gtmLabel}
                        url={AppConfigService.getNehnutelnostiUrl() + info.url}
                    />
                </Box>
            ))}
        </Box>
    );
};
