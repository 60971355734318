import { AxiosResponse } from 'axios';
import { homePageDevProjectsApi, realEstateLocationApi } from 'modules/api/client';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { IHomePageDevProject } from 'modules/api/clients/homePage/interfaces/devProjects/IHomePageDevProject';
import { HomePageDevProjectsDto, RealEstateAgencyListingLocationCollectionDto } from 'modules/api/generated';
import { mapHomePageDevProjectsDtoToDevProjectsInterface } from 'modules/api/clients/homePage/helpers/mappers/devProjects/homePageDevProjectsDtoToDevProjectsInterfaceMapper';
import { mapRealEstateLocationDtoToILocation } from 'modules/api/clients/homePage/helpers/mappers/locations/mapRealEstateLocationDtoToILocation';

export class HomePage {
    public async getHomePageDevProjects(
        limit: number,
        skip: number
    ): Promise<IHomePageDevProject[]> {
        let response: AxiosResponse<HomePageDevProjectsDto>;

        try {
            response =
                await homePageDevProjectsApi.getHomePageDevProjects(limit, skip);
        } catch (error) {
            throw new Error(
                `Failed to get dev projects. Error: ${error.message}`
            );
        }

        if (response.status !== 200) {
            throw new Error(`Getting dev projects with limit ${limit} and skip ${skip} failed`);
        }

        return mapHomePageDevProjectsDtoToDevProjectsInterface(response.data.projects);
    }

    public async getBaseFilterLocations(): Promise<ILocation[]> {
        let response: AxiosResponse<RealEstateAgencyListingLocationCollectionDto>;

        try {
            response = await realEstateLocationApi.getLocations();
        } catch (error) {
            throw new Error(
                `Failed to get base real estate locations. Error: ${error.message}`
            );
        }

        if (response.status !== 200) {
            throw new Error('Getting base real estate locations failed');
        }

        return response.data.items.map((realEstateLocation) =>
            mapRealEstateLocationDtoToILocation(realEstateLocation)
        );
    }
}