import { FC } from 'react';
import { Box } from '@mui/material';
import Image from 'components/image/Image';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { Content } from 'components/page/homePage/components/filter/partials/content/Content';
import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import BackgroundImage from 'components/page/homePage/components/filter/assets/HomePageFilterBackgroundImage.webp';

interface IFilter {
    baseFilterLocations: ILocation[];
    initialSearchFilterData: IInitialSearchFilterData;
    totalRoundedCount: number;
}

export const Filter: FC<IFilter> = ({
    baseFilterLocations,
    initialSearchFilterData,
    totalRoundedCount,
}) => {
    return (
        <Box overflow={'visible'}>
            <Box
                position={'relative'}
                maxHeight={{
                    xs: 400,
                    md: 500,
                    lg: 560,
                }}
                minHeight={{
                    xs: 400,
                    md: 350,
                }}
                height={{
                    xs: 'calc(100vh - 325px)',
                    md: 'calc(100vh - 275px)',
                }}

            >
                <Box>
                    <Image
                        fill
                        priority
                        alt={'background-filter-image'}
                        style={{
                            objectFit: 'cover',
                            borderRadius: '20px'
                        }}
                        src={BackgroundImage}
                    />
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        bgcolor="rgba(0, 0, 0, 0.2)"
                        borderRadius="20px"
                    />
                    <Content
                        baseFilterLocations={baseFilterLocations}
                        initialSearchFilterData={initialSearchFilterData}
                        totalRoundedCount={totalRoundedCount}
                    />
                </Box>
            </Box>
        </Box>
    );
};