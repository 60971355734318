import { Palette } from '@mui/material';
import { EBadgeType } from 'modules/theme/components/listing/cards/advertisementCard/enums/badge/EBadgeType';
import { IBadgeStyle } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/badge/IBadgeStyle';

export const BADGE_TYPE_AND_STYLE_RECORD = (
    palette: Palette
): Record<EBadgeType, IBadgeStyle> => ({
    [EBadgeType.BASIC]: {
        color: palette.labelPrimaryInverse,
        bgColor: palette.labelPrimary.main,
    },
    [EBadgeType.TOP]: {
        color: palette.labelPrimaryInverse,
        bgColor: palette.tintPink.main,
    },
    [EBadgeType.PREMIUM]: {
        bgColor: palette.tintPurple.main,
        color: palette.labelPrimaryInverse,
    },
    [EBadgeType.EXSLUSIVE]: {
        color: palette.labelPrimaryInverse,
        bgColor:
            'linear-gradient(135deg, #FF5793 0%, #FF5C00 100%, #FF5C00 100%)', //tieto farby nemame v palete
    },
    [EBadgeType.AGENT]: {
        color: palette.labelPrimaryInverse,
        bgColor: palette.labelPrimary.main,
    },
    [EBadgeType.AGENT_PRO]: {
        bgColor: palette.tintPink.main,
        color: palette.labelPrimaryInverse,
    },
    [EBadgeType.RESERVED]: {
        color: palette.labelPrimaryInverse,
        bgColor: palette.labelPrimary.main,
    },
    [EBadgeType.PRIVATE_PERSON]: {
        color: palette.labelSecondary,
        bgColor: palette.labelQuarternary.main,
    },
});
