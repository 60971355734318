import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Title } from 'components/page/homePage/components/whatAreYouLookingFor/partials/title/Title';
import { SubTitle } from 'components/page/homePage/components/whatAreYouLookingFor/partials/subTitle/SubTitle';
import { IconsBoxes } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/IconsBoxes';

export const WhatAreYouLookingFor: FC = () => {

    return (
        <Box paddingTop={{
            xs: 6,
            md: 8,
        }}>
            <Box mb={{
                xs: 1.5,
                md: 2,
            }}>
                <Stack justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                    <Box mb={{
                        xs: 0.5,
                        md: 1,
                    }}>
                        <Title />
                    </Box>
                    <SubTitle />
                </Stack>
            </Box>
            <Box>
                <IconsBoxes />
            </Box>
        </Box>
    );
};
