import {Text} from 'modules/theme/components/text/Text';
import {FC} from 'react';

interface ITitle {
    projectName: string
}

export const Title: FC<ITitle> = ({projectName}) => {
    return < Text inheritColor variant="h6" semibold noWrap>
        {projectName}
    </Text>;
};
