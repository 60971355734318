import { IHomePageDevProject } from 'modules/api/clients/homePage/interfaces/devProjects/IHomePageDevProject';
import { useCallback, useState } from 'react';
import { HomePage } from 'modules/api/clients/homePage/HomePage';

interface IReturn {
    isFetching: boolean;
    devProjects: IHomePageDevProject[];
    fetchDevProjects: () => void;
}

const LIMIT = 8;

export const useDevProjects = (devProjectsInitial: IHomePageDevProject[]): IReturn => {
    const [devProjects, setDevProjects] = useState<IHomePageDevProject[]>(devProjectsInitial);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchDevProjects = useCallback(async () => {
        setIsFetching(true);

        try {
            const newDevProjects = await new HomePage().getHomePageDevProjects(LIMIT, devProjects.length);

            setDevProjects([...devProjects, ...newDevProjects]);
        } catch (error) {
            throw new Error(
                `Failed to fetch devProjects. Error: ${error.message}`
            );
        }

        setIsFetching(false);
    }, [devProjects]);

    return {
        isFetching,
        devProjects,
        fetchDevProjects
    };
};