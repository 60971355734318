import { FC } from 'react';
import { Box } from '@mui/material';
import { CloseButton } from 'components/page/advertisement/common/closeButton/CloseButton';
import { Title } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/modal/partials/header/partials/title/Title';

interface IHeader {
    title: string;
    onClick: () => void;
    onTitleClick: () => void
}

export const Header: FC<IHeader> = ({ title, onClick, onTitleClick }) => {
    return (
        <>
            <Box mb={2}>
                <CloseButton onClick={onClick} />
            </Box>
            <Title text={title} onTitleClick={onTitleClick}/>
        </>
    );
};
