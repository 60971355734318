import {FC, ReactNode} from 'react';
import {Stack} from '@mui/material';

interface IWrapper {
    gap?: number;
    isLast?: boolean;
    shrink?: boolean;
    children: ReactNode;
}

export const ParameterWrapper: FC<IWrapper> = ({children, isLast, shrink, gap = .5}) => {
    return <Stack
        gap={gap}
        direction="row"
        overflow="hidden"
        alignItems="center"
        flexShrink={(shrink && !isLast) ? 0 : 1}
    >
        {children}
    </Stack>;
};
