import { FC } from 'react';
import { MobilePopularRegions } from 'components/page/homePage/components/popularRegions/mobile/MobilePopularRegions';
import { DesktopPopularRegions } from 'components/page/homePage/components/popularRegions/desktop/DesktopPopularRegions';
import { popularRegionItems } from 'components/page/homePage/components/popularRegions/common/constants/PopularRegionItems';
import { Box } from '@mui/system';
import {Text} from 'modules/theme/components/text/Text';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

export const PopularRegions: FC = () => {
    
    return (
        <Box paddingTop={3} 
            paddingBottom={{
                xs: 8,
                md: 10,
            }}
        >
            <Box width={'100%'} display={'flex'} justifyContent={'center'} marginTop={3} marginBottom={3}>
                <Text variant={'h4'} mobileVariant={'h5'} component={'h3'} semibold>
                    Populárne regióny na Slovensku
                </Text>
            </Box>
            <Mobile>
                <MobilePopularRegions items={popularRegionItems} />
            </Mobile>
            <Desktop>
                <DesktopPopularRegions items={popularRegionItems} />
            </Desktop>

        </Box>
    );
};
