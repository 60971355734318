import {
    ECategoriesLabel
} from 'modules/theme/components/listing/cards/promoDevProjectCard/enums/category/ECategoriesLabel';

export const CategoriesOrder: ECategoriesLabel[] = [
    ECategoriesLabel.APARTMENTS,
    ECategoriesLabel.HOUSES,
    ECategoriesLabel.COTTAGES_AND_CABINS,
    ECategoriesLabel.OBJECTS,
    ECategoriesLabel.SPACES,
];
