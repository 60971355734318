import {FC, ReactNode} from 'react';
import Link from 'components/link/Link';
import {Box, Theme, useMediaQuery} from '@mui/material';

interface IWrapper {
    href?: string;
    onWrapperClick?: () => void;
    children: ReactNode | ReactNode[];
}

export const Wrapper: FC<IWrapper> = ({children, href, onWrapperClick}) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return <Link noUnderline openInNewTab={!isMobile} href={href} onClick={onWrapperClick} tabIndex={-1}>
        <Box borderRadius={2} overflow="hidden" height={254}>
            {children}
        </Box>
    </Link>;
};
