import React, { FC } from 'react';
import { IPopularRegion } from 'components/page/homePage/components/popularRegions/common/interfaces/IPopularRegion';
import { Grid } from '@mui/material';
import { PopularRegionItem } from 'components/page/homePage/components/popularRegions/common/PopularRegionItem';

interface IProps {
    items: IPopularRegion[]
}

export const DesktopPopularRegions: FC<IProps> = ({ items }) => {
    return (
        <Grid container spacing={1}>
            {items.map((item, index) => (
                <Grid item xs={3} key={index}>
                    <PopularRegionItem item={item} />
                </Grid>
            ))}
        </Grid>
    );
};