import {OverridableStringUnion} from '@mui/types';
import {FC, FunctionComponent, SVGProps} from 'react';
import {Svg} from 'modules/theme/components/svg/Svg';
import {Text} from 'modules/theme/components/text/Text';
import {Variant} from '@mui/material/styles/createTypography';
import {TypographyPropsVariantOverrides} from '@mui/material/Typography/Typography';
import {
    ParameterWrapper
} from 'modules/theme/components/listing/cards/common/components/parameters/common/ParameterWrapper';

interface IParameter {
    value: string;
    noWrap?: boolean;
    isLast?: boolean;
    shrink?: boolean;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>
    variant: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
}

export const Parameter: FC<IParameter> = ({
    icon,
    noWrap,
    variant,
    value,
    isLast,
    shrink,
}) => {
    return <ParameterWrapper isLast={isLast} shrink={shrink}>
        {icon && <Svg icon component={icon} width={16}/>}
        <Text variant={variant} inheritColor noWrap={noWrap}>{value}</Text>
    </ParameterWrapper>;
};
