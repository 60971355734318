import {FC} from 'react';
import {Box} from '@mui/material';
import {Figure} from 'modules/theme/components/assets/blankPlaceholder/Figure';

interface IPhoto {
    photoUrl?: string;
}

export const Photo: FC<IPhoto> = ({photoUrl}) => {
    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="start"
            justifyContent="center"
            sx={{
                background: `
                    linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%),
                    url(${photoUrl}),
                    lightgray no-repeat
                `,
                backgroundSize: 'cover',
            }}
        >
            {!photoUrl && <Figure width="50%"/>}
        </Box>
    );
};
