
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum } from 'modules/api/generated';

export const categoryRecord: Record<HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum, EMainCategory> = {
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.Apartments]: EMainCategory.APARTMENTS,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.CottagesAndCabins]: EMainCategory.COTTAGES_AND_CABINS,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.Houses]: EMainCategory.HOUSES,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.Objects]: EMainCategory.OBJECTS,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.Spaces]: EMainCategory.SPACES,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.RealEstates]: EMainCategory.REAL_ESTATES,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.Lands]: EMainCategory.LANDS,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.SecondaryHouses]: EMainCategory.SECONDARY_HOUSES,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.SecondaryLands]: EMainCategory.SECONDARY_LANDS,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.SecondaryRecreationalProperties]: EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES,
    [HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum.SecondarySpacesAndObjects]: EMainCategory.SECONDARY_SPACES_AND_OBJECTS,
};