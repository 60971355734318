import { RealEstateAgencyListingLocationDto } from 'modules/api/generated';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

export const mapRealEstateLocationDtoToILocation = (
    dto: RealEstateAgencyListingLocationDto,
): ILocation => ({
    id: dto.id,
    name: dto.name,
    sefName: dto.sefName,
    isForeign: dto.isForeign,
    ...(!!dto.isParent && { isParent: dto.isParent }),
});