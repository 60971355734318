import { FC } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { Content } from 'components/loginModal/partials/content/Content';

interface ILoginModal {
    onClose?: () => void;
}

const LoginModal: FC<ILoginModal> = ({ onClose }) => {
    return (
        <ClickAwayListener width={400} onClickAway={() => onClose()} touchEvent={false}>
            <Box>
                <Content onClose={onClose}/>
            </Box>
        </ClickAwayListener>
    );
};

export default LoginModal;
