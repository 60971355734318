import { HomePageDevProjectsDevProjectLocationDto } from 'modules/api/generated';
import { ILocation } from 'modules/api/clients/homePage/interfaces/devProjects/ILocation';

export const mapHomePageDevProjectsLocationDtoToLocationInterfaceMapper = (dto: HomePageDevProjectsDevProjectLocationDto): ILocation => {
    return {
        name: dto.name,
        street: dto?.street || null,
        city: dto?.city || null,
        county: dto?.county || null,
        district: dto?.district || null,
        country: dto?.country || null
    };
};