import { Svg } from 'modules/theme/components/svg/Svg';
import AddCircleIcon from 'modules/theme/components/icons/module/NewAdd.svg';
import ValuationIcon from 'modules/theme/components/icons/module/Valuation.svg';
import ListingIcon from 'modules/theme/components/icons/module/Listing.svg';
import CalculatorIcon from 'modules/theme/components/icons/basic/Calculator.svg';
import LandCheckIcon from 'modules/theme/components/icons/module/LandCheck.svg';
import BrokerIcon from 'modules/theme/components/icons/module/Broker.svg';
import { ELabel } from 'modules/gtmEvents/enums/homePage/ELabel';
import { IAdditionalService } from 'components/additionalServicesList/interfaces/IAdditionalService';

export const additionalServices: IAdditionalService[] = [
    {
        icon: <Svg icon component={AddCircleIcon} color={'identityBasicPink'} width={32} height={32}/>,
        name: 'Pridať\ninzerát',
        url: '/pridanie',
        gtmLabel: ELabel.ADD,
    },
    {
        icon: <Svg icon component={BrokerIcon} color={'identityDarkBlue'} width={32} height={32}/>,
        name: ' Maklér\nna kľúč',
        url: '/pomozeme-vam-s-predajom',
        gtmLabel: ELabel.BROKER,
    },
    {
        icon: <Svg icon component={ValuationIcon} color={'identityDarkYellow'} width={32} height={32} />,
        name: 'Ocenenie\nnehnuteľností',
        url: '/ocenovanie-nehnutelnosti',
        gtmLabel: ELabel.VALUATE,
    },
    {
        icon: <Svg icon component={CalculatorIcon} color={'identityBasicGreen'} width={32} height={32}/>,
        name: 'Výpočet\nhypotéky',
        url: '/vypocet-hypoteky',
        gtmLabel: ELabel.CALCULATE,
    },
    {
        icon: <Svg icon component={LandCheckIcon} color={'identityBasicRed'} width={32} height={32}/>,
        name: 'Overovanie\npozemkov',
        url: '/trhove-reporty/marketplace/overovanie-pozemkov',
        gtmLabel: ELabel.LANDCHECK,
    },
    {
        icon: <Svg icon component={ListingIcon} color={'labelSecondary'} width={32} height={32}/>,
        name: 'Zmluvy\na informácie',
        url: '/informacie/',
        gtmLabel: ELabel.LISTING,
    },
];