import { FC, useMemo } from 'react';
import useSession from 'modules/state/app/hook/session/useSession';
import { DesktopBecomeMember } from 'components/page/homePage/components/becomeMember/desktop/DesktopBecomeMember';
import { MobileBecomeMember } from 'components/page/homePage/components/becomeMember/mobile/MobileBecomeMember';
import { AdditionalServicesList } from 'components/additionalServicesList/AdditionalServicesList';
import { Box } from '@mui/system';
import { DesktopLoggedInBecomeMember } from 'components/page/homePage/components/becomeMember/desktop/DesktopLoggedInBecomeMember';
import { MobileLoggedInBecomeMember } from 'components/page/homePage/components/becomeMember/mobile/MobileLoggedInBecomeMember';
import { additionalServices } from 'components/page/homePage/components/becomeMember/constants/AdditionalServices';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

export const BecomeMember: FC = () => {
    const { session } = useSession();

    const isLoggedIn = !!session;

    const parsedUserName = useMemo((): string => {
        if (!session?.nick) return '';
        if (!session.nick.includes(' ')) return session.nick;
        return session.nick.split(' ')[0];
    }, [session?.nick]);

    return (
        <Box
            paddingLeft={0}
            paddingRight={0}
            paddingTop={{
                xs: 5,
                md: 6,
            }}
            paddingBottom={{
                xs: 6,
                md: 8,
            }}
        >
            <Mobile>
                {isLoggedIn ? (
                    <MobileLoggedInBecomeMember name={parsedUserName} />
                ) : (
                    <MobileBecomeMember />
                )}
            </Mobile>
            <Desktop>
                {isLoggedIn ? (
                    <DesktopLoggedInBecomeMember name={parsedUserName} />
                ) : (
                    <DesktopBecomeMember />
                )}
            </Desktop>
            <Box>
                <AdditionalServicesList
                    additionalServices={additionalServices}
                />
            </Box>
        </Box>
    );
};
